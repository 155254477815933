var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticStyle: { "max-width": "1600px" }, attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("load-tracking"),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  id: "tblShipments",
                  headers: _vm.headers,
                  items: _vm.shipments,
                  "items-per-page": _vm.pageSize,
                  "calculate-widths": "",
                  "fixed-header": "",
                  "hide-default-footer": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.id",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              to: {
                                name: "Load Detail",
                                params: { id: item.id },
                              },
                              small: "",
                              target: "_blank",
                              text: "",
                            },
                          },
                          [_vm._v(" " + _vm._s(item.id) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.loadStatus",
                    fn: function ({ item }) {
                      return [
                        _c("load-status-button", {
                          attrs: {
                            "agent-detail": _vm.agentDetail,
                            "load-status-items": _vm.loadStatusChoices,
                            "shipment-detail": item,
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "item.shipper",
                    fn: function ({ item }) {
                      return [
                        item.shipper !== null
                          ? _c("p", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatLocation(
                                      item.shipper.city,
                                      item.shipper.stateProvinceRegion
                                    )
                                  )
                                ),
                              ]),
                              _c("br"),
                              _c("span", { staticClass: "subtitle" }, [
                                _vm._v(_vm._s(item.pickupDate)),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.consignee",
                    fn: function ({ item }) {
                      return [
                        item.consignee !== null
                          ? _c("p", [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatLocation(
                                      item.consignee.city,
                                      item.consignee.stateProvinceRegion
                                    )
                                  )
                                ),
                              ]),
                              _c("br"),
                              _c("span", { staticClass: "subtitle" }, [
                                _vm._v(_vm._s(item.deliveryDate)),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.margin",
                    fn: function ({ item }) {
                      return [
                        item.margin !== null
                          ? _c("p", { staticClass: "mb-0" }, [
                              _vm._v(_vm._s(item.margin) + "%"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.customerPrice",
                    fn: function ({ item }) {
                      return [
                        item.customerPrice !== null
                          ? _c("p", { staticClass: "mb-0" }, [
                              _vm._v(" $" + _vm._s(item.customerPrice) + " "),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.carrierPrice",
                    fn: function ({ item }) {
                      return [
                        item.carrierPrice !== null
                          ? _c("p", { staticClass: "mb-0" }, [
                              _vm._v(" $" + _vm._s(item.carrierPrice) + " "),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c("v-page", {
                          staticClass: "pt-3 pr-3 pb-3",
                          attrs: {
                            "page-size-menu": [10, 20, 50, 100],
                            "total-row": _vm.totalRecord,
                          },
                          on: { "page-change": _vm.readDataFromAPI },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
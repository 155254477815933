var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        {
          staticClass: "pt-0",
          staticStyle: { "max-width": "1600px" },
          attrs: { fluid: "" },
        },
        [
          _c(
            "v-card",
            { staticClass: "mb-0" },
            [
              _c(
                "div",
                { staticClass: "pa-2 header-banner" },
                [
                  _c("v-row", [
                    _c("div", { staticClass: "col-md-6 d-flex align-center" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "white rounded-circle d-inline-block align-center text-center short-name",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "transition-swing font-weight-bold grey--text",
                              staticStyle: { "font-size": "48px !important" },
                            },
                            [_vm._v(" " + _vm._s(_vm.shortCustomerName) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "white--text ml-4 mt-4" },
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass: "mb-1",
                              staticStyle: { "font-size": "24px" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.currentCustomer.name) + " "
                              ),
                            ]
                          ),
                          _c("div", { staticStyle: { "font-size": "18px" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.currentCustomer.address) + " "
                            ),
                          ]),
                          _c("div", { staticStyle: { "font-size": "18px" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatLocation(
                                    _vm.currentCustomer.city,
                                    _vm.currentCustomer.state
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _c("v-switch", {
                            attrs: { color: "secondary" },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "white--text text-h4" },
                                      [
                                        _vm._v(
                                          "Customer Status: " +
                                            _vm._s(
                                              _vm.currentStatus
                                                ? "Active"
                                                : "Inactive"
                                            )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.currentStatus,
                              callback: function ($$v) {
                                _vm.currentStatus = $$v
                              },
                              expression: "currentStatus",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-md-6 white--text mt-5 mb-3 pl-4 pr-10 text-h3 text-md-right",
                      },
                      [
                        _vm._v(" Account Owner: "),
                        _c("span", { staticClass: "font-weight-medium" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.currentCustomer.accountOwnerDisplay) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { centered: "" },
                      on: { change: _vm.changeTab },
                      model: {
                        value: _vm.currentTab,
                        callback: function ($$v) {
                          _vm.currentTab = $$v
                        },
                        expression: "currentTab",
                      },
                    },
                    [
                      _c("v-tab", { attrs: { href: "#tab-1" } }, [
                        _vm._v(" Summary "),
                      ]),
                      _c("v-tab", { attrs: { href: "#tab-2" } }, [
                        _vm._v(" Details "),
                      ]),
                      _c("v-tab", { attrs: { href: "#tab-3" } }, [
                        _vm._v(" Shipments "),
                      ]),
                      _c("v-tab", { attrs: { href: "#tab-4" } }, [
                        _vm._v(" Documents "),
                      ]),
                      _c("v-tab", { attrs: { href: "#tab-5" } }, [
                        _vm._v(" Notes "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-progress-linear", {
                attrs: {
                  active: _vm.loadingIcon,
                  color: "amber",
                  indeterminate: "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          staticStyle: { background: "none" },
          model: {
            value: _vm.currentTab,
            callback: function ($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab",
          },
        },
        [
          _c(
            "v-tab-item",
            { attrs: { value: "tab-1" } },
            [_c("customer-summary", { attrs: { "customer-id": _vm.id } })],
            1
          ),
          _c(
            "v-tab-item",
            { attrs: { value: "tab-2" } },
            [_c("customer-details", { attrs: { "customer-id": _vm.id } })],
            1
          ),
          _c(
            "v-tab-item",
            { attrs: { value: "tab-3" } },
            [
              _c("customer-shipments", {
                ref: "shipmentTab",
                attrs: { "customer-id": _vm.id },
              }),
            ],
            1
          ),
          _c(
            "v-tab-item",
            { attrs: { value: "tab-4" } },
            [_c("customer-documents", { attrs: { "customer-id": _vm.id } })],
            1
          ),
          _c(
            "v-tab-item",
            { attrs: { value: "tab-5" } },
            [_c("customer-notes", { attrs: { "customer-id": _vm.id } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticStyle: { "max-width": "1600px" }, attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c(
                "v-card",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-container",
                    { staticClass: "blue-grey lighten-5" },
                    [_c("v-toolbar-title", [_vm._v(" Notes ")])],
                    1
                  ),
                  _c(
                    "v-container",
                    { staticStyle: { height: "300px", "overflow-y": "auto" } },
                    [
                      _c(
                        "v-card-text",
                        _vm._l(_vm.contents, function (item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c(
                                "p",
                                { staticClass: "grey--text text-caption mb-2" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.format_date(item.updatedAt)) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("p", {
                                staticClass: "text-body-1 mb-2",
                                domProps: {
                                  innerHTML: _vm._s(
                                    item.note.replace(/(?:\r\n|\r|\n)/g, "<br>")
                                  ),
                                },
                              }),
                              _c(
                                "v-card-actions",
                                { staticClass: "pl-0 pt-0" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        small: "",
                                        color: "normal",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editNote(item.id)
                                        },
                                      },
                                    },
                                    [_vm._v(" Edit ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        small: "",
                                        color: "error",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.confirmDeleteNote(item.id)
                                        },
                                      },
                                    },
                                    [_vm._v(" Delete ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8" } },
            [
              _c(
                "v-card",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-container",
                    { staticClass: "blue-grey lighten-5" },
                    [_c("v-toolbar-title", [_vm._v(" Create Note ")])],
                    1
                  ),
                  _c(
                    "v-container",
                    { staticStyle: { height: "300px" } },
                    [
                      _c("v-textarea", {
                        staticClass: "ml-2 pt-0",
                        attrs: { rows: "5", clearable: "", "no-resize": "" },
                        model: {
                          value: _vm.noteDetail.note,
                          callback: function ($$v) {
                            _vm.$set(_vm.noteDetail, "note", $$v)
                          },
                          expression: "noteDetail.note",
                        },
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "blue white--text font-weight-bold offset-md-9",
                          on: {
                            click: function ($event) {
                              return _vm.saveNote()
                            },
                          },
                        },
                        [_vm._v(" Save Note ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "gray white--text font-weight-bold ml-2",
                          on: {
                            click: function ($event) {
                              return _vm.cancelNote()
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", [_vm._v(" Please confirm note deletion ")]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "grey darken-1", text: "", small: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "orange darken-1", text: "", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.removeRecord()
                        },
                      },
                    },
                    [_vm._v(" Delete ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticStyle: { "max-width": "1600px" }, attrs: { fluid: "" } },
    [
      _vm.uploadProgress > 0
        ? _c(
            "v-progress-linear",
            {
              attrs: { height: "25" },
              model: {
                value: _vm.uploadProgress,
                callback: function ($$v) {
                  _vm.uploadProgress = $$v
                },
                expression: "uploadProgress",
              },
            },
            [
              _c("strong", [
                _vm._v(_vm._s(Math.ceil(_vm.uploadProgress)) + "%"),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "text-right pa-0", attrs: { cols: "12" } },
            [
              !_vm.addDocument
                ? _c(
                    "v-btn",
                    {
                      staticClass:
                        "blue--text text--lighten-2 font-weight-bold",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.showAddDocument()
                        },
                      },
                    },
                    [_vm._v(" Add Document ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          !_vm.addDocument
            ? _c(
                "v-col",
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      id: "tblDocument",
                      headers: _vm.headers,
                      items: _vm.documents,
                      "calculate-widths": "",
                      "disable-pagination": "",
                      "hide-default-footer": "",
                      "hide-slider": "",
                      "fixed-header": "",
                      loading: _vm.loadingIcon,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.file",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "a",
                                {
                                  staticStyle: { "text-decoration": "none" },
                                  attrs: { href: item.file, target: "_blank" },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass:
                                        "pl-3 pr-1 gray--text text--lighten-2",
                                      attrs: { medium: "" },
                                    },
                                    [_vm._v(" mdi-file-document ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.modifyDetail",
                          fn: function ({ item }) {
                            return [
                              item.modifyDetail !== null
                                ? _c(
                                    "p",
                                    {
                                      staticClass: "mb-0",
                                      staticStyle: {
                                        width: "70%",
                                        float: "left",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.format_date(
                                                item.modifyDetail.updatedAt
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("br"),
                                      _c("span", { staticClass: "subtitle" }, [
                                        _vm._v(
                                          _vm._s(
                                            item.modifyDetail.uploadedByDisplay
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-menu",
                                {
                                  attrs: { bottom: "", left: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "float-right",
                                                    attrs: { icon: "" },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-dots-vertical"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { text: "", small: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editDocument(item)
                                                },
                                              },
                                            },
                                            [_vm._v(" Edit ")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "red--text",
                                              attrs: { text: "", small: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteDocument(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Delete ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      886827899
                    ),
                  }),
                ],
                1
              )
            : _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-text-field", {
                                staticClass: "col-md-4 pa-2 offset-md-4",
                                attrs: { type: "text", label: "Name" },
                                model: {
                                  value: _vm.documentName,
                                  callback: function ($$v) {
                                    _vm.documentName = $$v
                                  },
                                  expression: "documentName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c("v-file-input", {
                                staticClass: "col-md-4 offset-md-4",
                                attrs: {
                                  "show-size": "",
                                  counter: "",
                                  label: "File input",
                                  accept: "image/*,.pdf,.doc,.docx,.csv",
                                },
                                model: {
                                  value: _vm.fileInput,
                                  callback: function ($$v) {
                                    _vm.fileInput = $$v
                                  },
                                  expression: "fileInput",
                                },
                              }),
                              _vm.hasEdit
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.fileURL,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("View File")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _vm.uploadProgress > 0
                                ? _c(
                                    "v-progress-linear",
                                    {
                                      attrs: { height: "25" },
                                      model: {
                                        value: _vm.uploadProgress,
                                        callback: function ($$v) {
                                          _vm.uploadProgress = $$v
                                        },
                                        expression: "uploadProgress",
                                      },
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            Math.ceil(_vm.uploadProgress)
                                          ) + "%"
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "blue white--text font-weight-bold mt-4 offset-md-4",
                                      attrs: { text: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.uploadDocument()
                                        },
                                      },
                                    },
                                    [_vm._v(" Upload ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "grey white--text font-weight-bold mt-4 ml-2",
                                      attrs: { text: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.cancelUpload()
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", [_vm._v(" Please confirm document deletion ")]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "grey darken-1", text: "", small: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "orange darken-1", text: "", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.removeRecord()
                        },
                      },
                    },
                    [_vm._v(" Delete ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }